<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Initiate Workflow" icon="fa-solid fa-sitemap"/>

    <div class="generalBox border shadow-sm">
        <div class="row">
            <div class="col-12 mb-3 fw-bold">
                <Popper class="popperDark" arrow hover content="Back">
                    <fa icon="angle-left" size="lg" class="me-3 isLink" @click="$router.push({ path: '/project' })" />
                </Popper>
                Change Request - AccMgr
            </div>
            <!-- Left column -->
            <div class="col-lg-6">
                <div class="mb-3">
                    <div class="form-floating">
                        <input type="text" class="form-control" v-model="runPrefix" id="folderName" placeholder="Folder Name">
                        <label for="folderName">Folder Name</label>
                    </div>
                </div>

                <div class="mb-3">
                    <div class="mb-1">STEP 6 - External</div>
                    <div class="form-floating mb-2">
                        <input type="text" class="form-control" v-model="runPrefix" id="extName1" placeholder="Name">
                        <label for="extName1">Name</label>
                    </div>  
                    <div class="form-floating">
                        <input type="text" class="form-control" v-model="runPrefix" id="extEmail1" placeholder="Email">
                        <label for="extEmail1">Email</label>
                    </div>    
                </div>

                <div class="mb-3">
                    <div class="mb-1">STEP 7 - External</div>
                    <div class="form-floating mb-2">
                        <input type="text" class="form-control" v-model="runPrefix" id="extName2" placeholder="Name">
                        <label for="extName2">Name</label>
                    </div>  
                    <div class="form-floating">
                        <input type="text" class="form-control" v-model="runPrefix" id="extEmail2" placeholder="Email">
                        <label for="extEmail2">Email</label>
                    </div>    
                </div>
            </div>

            <!-- Right column -->
            <div class="col-lg-1 d-none d-lg-block"></div>
            <div class="col-12 col-lg-5">
                <table class="table">
                    <tbody>
                        <tr>
                            <td width="80px">STEP 1</td>
                            <td><fa icon="user-pen" size="lg" class="me-2" />Initiator</td>
                        </tr>
                        <tr>
                            <td>STEP 2</td>
                            <td><fa icon="users" size="lg" class="me-2" />Developer</td>
                        </tr>
                        <tr>
                            <td>STEP 3</td>
                            <td><fa icon="user" size="lg" class="me-3" />Enerson Yap</td>
                        </tr>
                        <tr>
                            <td>STEP 4</td>
                            <td>
                                <fa icon="users" size="lg" class="me-2" />Finance
                                <div style="margin-top: 4px; margin-bottom: 4px; margin-left: 30px">OR</div>
                                <fa icon="users" size="lg" class="me-2" />Commercial
                            </td>
                        </tr>
                        <tr>
                            <td>STEP 5</td>
                            <td><fa icon="user-pen" size="lg" class="me-2" />Initiator</td>
                        </tr>
                        <tr>
                            <td>STEP 6</td>
                            <td><fa icon="user-slash" size="lg" class="me-2" />External</td>
                        </tr>
                        <tr>
                            <td>STEP 7</td>
                            <td><fa icon="user-slash" size="lg" class="me-2" />External</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-12"><hr /></div>
            <div class="col-12 text-center">
                <button class="btn btn-outline-secondary me-2" data-bs-toggle="modal" data-bs-target="#mdlAttachmentPreview">
                    <fa icon="file-lines" size="lg" class="me-2" />Preview
                </button>

                <button class="btn btn-secondary me-2">
                    <fa icon="envelope" size="lg" class="me-2" />Send Sign Request
                </button>

                <button class="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#mdlWorkflowCancel" disabled>
                    <fa icon="ban" size="lg" class="me-2" />Terminate
                </button>
            </div>

        </div>
    </div>

    <!-- Modal: Attachment Prview --> 
    <div
        class="modal fade" 
        id="mdlAttachmentPreview"
        tabindex="-1"
        aria-labelledby="AttachmentPreview"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-lg modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Attachment preview</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Attachment here
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->

    <!-- Modal: Attachment Prview --> 
    <div
        class="modal fade" 
        id="mdlWorkflowCancel"
        tabindex="-1"
        aria-labelledby="WorkflowCancel"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Terminate workflow</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Confirm to terminate workflow?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>

                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="circle-check" class="me-2" />Confirm
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->
</template>

<script>
import { ref, inject, onMounted, onUnmounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import { useRoute, useRouter } from 'vue-router'
import Alert from '@/components/Alert.vue'
import funcs from '@/functions/function'
import Popper from 'vue3-popper'

export default {
    name: 'WorkflowInitiate',
    components: {
        TopNavigation, Alert, Popper
    },
    setup () {
        const alert = ref([])

        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const closeAlert = (index) => {
            alert.value.splice(index, 1)

        }

        onMounted(() => {
            console.info('- - - id', route.params.workflowId)
        })

        return {
            route, router, alert, closeAlert, func,
        }
    }
}
</script>

<style>
</style>